import gsap from 'gsap';
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import SvgBox1 from '-!svg-react-loader!./images/box-1.svg';
import SvgBox2 from '-!svg-react-loader!./images/box-2.svg';
import SvgBox3 from '-!svg-react-loader!./images/box-3.svg';
import SvgBox4 from '-!svg-react-loader!./images/box-4.svg';
import SvgBox5 from '-!svg-react-loader!./images/box-5.svg';
import SvgBox6 from '-!svg-react-loader!./images/box-6.svg';
import SvgBox7 from '-!svg-react-loader!./images/box-7.svg';
import SvgBox8 from '-!svg-react-loader!./images/box-8.svg';
import SvgPlatform from '-!svg-react-loader!./images/platform.svg';
import SvgPlane1 from '-!svg-react-loader!./images/plane-1.svg';
import SvgPlane2 from '-!svg-react-loader!./images/plane-2.svg';
import SvgPlane3 from '-!svg-react-loader!./images/plane-3.svg';
import SvgLabelProblem1 from '-!svg-react-loader!./images/label-problem-1.svg';
import SvgLabelProblem2 from '-!svg-react-loader!./images/label-problem-2.svg';
import SvgLabelProblem3 from '-!svg-react-loader!./images/label-problem-3.svg';
import SvgLabelSolution1 from '-!svg-react-loader!./images/label-solution-1.svg';
import SvgLabelFoundations1 from '-!svg-react-loader!./images/label-foundations-1.svg';
import SvgLabelFoundations2 from '-!svg-react-loader!./images/label-foundations-2.svg';
import SvgLabelFoundations3 from '-!svg-react-loader!./images/label-foundations-3.svg';
import SvgCircle from '-!svg-react-loader!./images/circle.svg';

const HowItWorksAnimation = ({
  animid,
  currentAnimationStage,
  currentAnimationIsAbove,
  isMobileOnly,
}) => {
  const animationRef = useRef(null);
  const classPrefix = 'how-it-works-animation';
  const id = `animation-${animid}`;
  const classes = isMobileOnly
    ? `${classPrefix} ${classPrefix}--mobile`
    : `${classPrefix}`;
  const duration = 0.75;
  const ease = 'power2.out';
  const green = '#5fdd9d';
  const blue = '#1976d2';
  const yellow = '#f8df3e';

  const getX = (x, w) => {
    return (x / 872) * w;
  };

  const getY = (y, h) => {
    return (y / 768) * h;
  };

  const animateBox = (element, fill, x1, y1, x2, y2, zIndex) => {
    const boxTl = gsap.timeline();
    boxTl.to(element, {
      duration,
      x: x1,
      y: y1,
      opacity: 1,
      zIndex,
      ease,
    });
    if (x2 !== null && y2 !== null) {
      boxTl.to(element, {
        duration,
        x: x2,
        y: y2,
        zIndex,
        ease,
      });
    }
    if (fill) {
      gsap.to(`${element} .fill`, { duration, fill });
    }
  };

  const animation1in = useCallback(() => {
    const w = animationRef.current.offsetWidth;
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animateBox(
      `#${id} .${classPrefix}__box-1`,
      green,
      0,
      getY(-260, h),
      getX(216, w),
      getY(117, h)
    ); // small green
    animateBox(
      `#${id} .${classPrefix}__box-2`,
      blue,
      0,
      0,
      getX(-250, w),
      getY(-174, h),
      5
    ); // small blue
    animateBox(
      `#${id} .${classPrefix}__box-3`,
      green,
      0,
      0,
      getX(-353, w),
      getY(62, h),
      4
    ); // medium green
    animateBox(
      `#${id} .${classPrefix}__box-4`,
      blue,
      0,
      getY(-50, h),
      getX(-221, w),
      getY(146, h),
      3
    ); // big blue
    animateBox(
      `#${id} .${classPrefix}__box-5`,
      yellow,
      0,
      getY(-25, h),
      getX(106, w),
      getY(-210, h),
      2
    ); // medium yellow
    animateBox(
      `#${id} .${classPrefix}__box-6`,
      yellow,
      0,
      getY(-140, h),
      getX(-139, w),
      getY(-118, h),
      4
    ); // small yellow
    animateBox(
      `#${id} .${classPrefix}__box-7`,
      yellow,
      0,
      getY(-260, h),
      getX(26, w),
      getY(-19, h),
      1
    ); // big yellow
    animateBox(
      `#${id} .${classPrefix}__box-8`,
      blue,
      0,
      getY(-200, h),
      getX(306, w),
      getY(-272, h)
    ); // medium blue
    gsap.to(
      `#${id} .${classPrefix}__platform-1, #${id} .${classPrefix}__platform-2, #${id} .${classPrefix}__platform-3`,
      { duration, delay: duration, y: getY(-665, h), x: 0, opacity: 1 }
    );
    gsap.to(`#${id} .${classPrefix}__planes`, {
      duration,
      y: getY(200, h),
      opacity: 0,
    });
    gsap.to(
      `#${id} .${classPrefix}__boxes, #${id} .${classPrefix}__boxes svg .fill`,
      { duration, y: 0, opacity: 1 }
    );
    gsap.to(
      `#${id} .${classPrefix}__label-problem-1, #${id} .${classPrefix}__label-problem-2, #${id} .${classPrefix}__label-problem-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__label-solution-1`, {
      delay: duration,
      duration,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-2, #${id} .${classPrefix}__label-foundations-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__circle circle`, {
      duration,
      fill: '#e3f7ec',
    });
    gsap.to(`#${id} .${classPrefix}__circle`, { duration, scale: 1.5 });
  }, [id]);

  const animation1out = useCallback(() => {
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animateBox(`#${id} .${classPrefix}__box-1`, green, 0, getY(-260, h), 0, 0); // small green
    animateBox(`#${id} .${classPrefix}__box-2`, blue, 0, 0, 0, 0, 3); // small blue
    animateBox(`#${id} .${classPrefix}__box-3`, green, 0, 0, 0, 0, 3); // medium green
    animateBox(`#${id} .${classPrefix}__box-4`, blue, 0, getY(-50, h), 0, 0, 2); // big blue
    animateBox(
      `#${id} .${classPrefix}__box-5`,
      yellow,
      0,
      getY(-25, h),
      0,
      0,
      1
    ); // medium yellow
    animateBox(
      `#${id} .${classPrefix}__box-6`,
      yellow,
      0,
      getY(-140, h),
      0,
      0,
      2
    ); // small yellow
    animateBox(
      `#${id} .${classPrefix}__box-7`,
      yellow,
      0,
      getY(-260, h),
      0,
      0,
      2
    ); // big yellow
    animateBox(`#${id} .${classPrefix}__box-8`, blue, 0, getY(-200, h), 0, 0); // medium blue
    gsap.to(
      `#${id} .${classPrefix}__platform-1, #${id} .${classPrefix}__platform-2, #${id} .${classPrefix}__platform-3`,
      { duration, y: 0, x: 0, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__planes`, {
      delay: duration,
      duration,
      y: 0,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__boxes, #${id} .${classPrefix}__boxes svg .fill`,
      { duration, y: 0, opacity: 1 }
    );
    gsap.to(
      `#${id} .${classPrefix}__label-problem-1, #${id} .${classPrefix}__label-problem-2, #${id} .${classPrefix}__label-problem-3`,
      { delay: duration * 2, duration, opacity: 1 }
    );
    gsap.to(`#${id} .${classPrefix}__label-solution-1`, {
      duration,
      opacity: 0,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-2, #${id} .${classPrefix}__label-foundations-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__circle circle`, {
      duration,
      fill: '#fdf9dc',
    });
    gsap.to(`#${id} .${classPrefix}__circle`, { duration, scale: 1 });
  }, [id]);

  const animationStage2Base = useCallback(
    (timing, w, h) => {
      // step 1
      gsap.to(`#${id} .${classPrefix}__box-1`, {
        duration: timing,
        x: getX(216, w),
        y: getY(117, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-2`, {
        duration: timing,
        x: getX(-250, w),
        y: getY(-174, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-3`, {
        duration: timing,
        x: getX(-353, w),
        y: getY(62, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-4`, {
        duration: timing,
        x: getX(-221, w),
        y: getY(146, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-5`, {
        duration: timing,
        x: getX(106, w),
        y: getY(-210, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-6`, {
        duration: timing,
        x: getX(-139, w),
        y: getY(-118, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-7`, {
        duration: timing,
        x: getX(26, w),
        y: getY(-19, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__box-8`, {
        duration: timing,
        x: getX(306, w),
        y: getY(-272, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-3`, {
        duration: timing,
        y: getY(-665, h),
        opacity: 1,
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-2`, {
        duration: timing,
        y: getY(-641, h),
        opacity: 1,
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-1`, {
        duration: timing,
        y: getY(-617, h),
        opacity: 1,
        ease,
      });
      gsap.to(
        `#${id} .${classPrefix}__platform-2 .front, #${id} .${classPrefix}__platform-2 .side`,
        { duration: timing, fill: '#e8834d' }
      );
      gsap.to(
        `#${id} .${classPrefix}__platform-1 .front, #${id} .${classPrefix}__platform-1 .side`,
        { duration: timing, fill: '#e54f47' }
      );
      gsap.to(`#${id} .${classPrefix}__planes`, {
        duration: timing,
        y: getY(200, h),
        opacity: 0,
      });
      gsap.to(
        `#${id} .${classPrefix}__label-problem-1, #${id} .${classPrefix}__label-problem-2, #${id} .${classPrefix}__label-problem-3`,
        { duration, opacity: 0 }
      );
      gsap.to(`#${id} .${classPrefix}__label-solution-1`, {
        duration: timing,
        opacity: 0,
      });
      gsap.to(`#${id} .${classPrefix}__circle circle`, {
        duration: timing,
        fill: '#ffffff',
        stroke: '#e48864',
      });
      // step 2
      gsap.to(`#${id} .${classPrefix}__boxes`, {
        delay: timing,
        duration: timing,
        opacity: 0.5,
      });
      gsap.to(`#${id} .${classPrefix}__boxes svg .fill`, {
        delay: timing,
        duration: timing,
        fill: '#fdeee8',
      });
      gsap.to(`#${id} .${classPrefix}__platform-3 .top`, {
        delay: timing,
        duration: timing,
        fill: '#fdeee8',
      });
      gsap.to(`#${id} .${classPrefix}__platform-2 .top`, {
        delay: timing,
        duration: timing,
        fill: '#f2d1bf',
      });
      gsap.to(`#${id} .${classPrefix}__platform-1 .top`, {
        delay: timing,
        duration: timing,
        fill: '#f6cac8',
      });
      gsap.to(
        `#${id} .${classPrefix}__platform-3 .front, #${id} .${classPrefix}__platform-3 .side`,
        { delay: duration, duration: timing, fill: '#ee5622' }
      );
      // step 3
      gsap.to(`#${id} .${classPrefix}__boxes`, {
        delay: timing * 2,
        duration: timing,
        y: getY(-80, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-3`, {
        delay: timing * 2,
        duration: timing,
        y: getY(-741, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-2`, {
        delay: timing * 2,
        duration: timing,
        y: getY(-681, h),
        ease,
      });
      gsap.to(`#${id} .${classPrefix}__platform-1`, {
        delay: timing * 2,
        duration: timing,
        y: getY(-627, h),
        ease,
      });
    },
    [id]
  );

  const animation2in = useCallback(() => {
    const w = animationRef.current.offsetWidth;
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animationStage2Base(duration, w, h);
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-2, #${id} .${classPrefix}__label-foundations-3`,
      { delay: duration, duration, opacity: 1 }
    );
  }, [id, animationStage2Base]);

  const animation2out = useCallback(() => {
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    // step 1
    gsap.to(`#${id} .${classPrefix}__boxes`, { duration, y: 0, ease });
    gsap.to(`#${id} .${classPrefix}__platform-3`, {
      duration,
      y: getY(-665, h),
      ease,
    });
    gsap.to(`#${id} .${classPrefix}__platform-2`, {
      duration,
      y: getY(-641, h),
      ease,
    });
    gsap.to(`#${id} .${classPrefix}__platform-1`, {
      duration,
      y: getY(-617, h),
      ease,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-2, #${id} .${classPrefix}__label-foundations-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__circle circle`, {
      duration,
      fill: '#e3f7ec',
      stroke: 'none',
    });
    // step 2
    gsap.to(
      `#${id} .${classPrefix}__box-1 .fill, #${id} .${classPrefix}__box-3 .fill`,
      { delay: duration, duration, fill: green }
    );
    gsap.to(
      `#${id} .${classPrefix}__box-2 .fill, #${id} .${classPrefix}__box-4 .fill, #${id} .${classPrefix}__box-8 .fill`,
      { delay: duration, duration, fill: blue }
    );
    gsap.to(
      `#${id} .${classPrefix}__box-5 .fill, #${id} .${classPrefix}__box-6 .fill, #${id} .${classPrefix}__box-7 .fill`,
      { delay: duration, duration, fill: yellow }
    );
    gsap.to(`#${id} .${classPrefix}__boxes`, {
      delay: duration,
      duration,
      opacity: 1,
    });
    gsap.to(`#${id} .${classPrefix}__boxes svg .fill`, {
      delay: duration,
      duration,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-3 .top, #${id} .${classPrefix}__platform-2 .top, #${id} .${classPrefix}__platform-1 .top`,
      { delay: duration, duration, fill: '#ee5622' }
    );
    gsap.to(
      `#${id} .${classPrefix}__platform-3 .front, #${id} .${classPrefix}__platform-3 .side`,
      { delay: duration, duration, fill: '#fdeee8' }
    );
    // step 3
    gsap.to(
      `#${id} .${classPrefix}__platform-1, #${id} .${classPrefix}__platform-2`,
      { delay: duration * 2, duration, y: getY(-665, h), ease }
    );
    gsap.to(
      `#${id} .${classPrefix}__platform-2 .front, #${id} .${classPrefix}__platform-2 .side, #${id} .${classPrefix}__platform-1 .front, #${id} .${classPrefix}__platform-1 .side`,
      { delay: duration * 2, duration, fill: '#fdeee8' }
    );
    gsap.to(`#${id} .${classPrefix}__label-solution-1`, {
      delay: duration * 3,
      duration,
      opacity: 1,
    });
  }, [id]);

  const animationHighlightSkpr = useCallback(() => {
    const w = animationRef.current.offsetWidth;
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animationStage2Base(0, w, h);
    gsap.to(`#${id} .${classPrefix}__label-foundations-1`, {
      duration,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-2, #${id} .${classPrefix}__label-foundations-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__platform-3 .top`, {
      duration,
      fill: '#fdeee8',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-3 .front, #${id} .${classPrefix}__platform-3 .side`,
      { duration, fill: '#e54f47' }
    );
    gsap.to(`#${id} .${classPrefix}__platform-2 .top`, {
      duration,
      fill: '#f2d1bf',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-1 .top, #${id} .${classPrefix}__platform-1 .front, #${id} .${classPrefix}__platform-1 .side, #${id} .${classPrefix}__platform-2 .front, #${id} .${classPrefix}__platform-2 .side`,
      { duration, fill: '#f6cac8' }
    );
  }, [id, animationStage2Base]);

  const animationHighlightKube = useCallback(() => {
    const w = animationRef.current.offsetWidth;
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animationStage2Base(0, w, h);
    gsap.to(`#${id} .${classPrefix}__label-foundations-2`, {
      duration,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-3`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__platform-3 .top`, {
      duration,
      fill: '#fdeee8',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-1 .top, #${id} .${classPrefix}__platform-1 .front, #${id} .${classPrefix}__platform-1 .side, #${id} .${classPrefix}__platform-3 .front, #${id} .${classPrefix}__platform-3 .side`,
      { duration, fill: '#f6cac8' }
    );
    gsap.to(`#${id} .${classPrefix}__platform-2 .top`, {
      duration,
      fill: '#e8834d',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-2 .front, #${id} .${classPrefix}__platform-2 .side`,
      { duration, fill: '#e54f47' }
    );
  }, [id, animationStage2Base]);

  const animationHighlightAws = useCallback(() => {
    const w = animationRef.current.offsetWidth;
    const h = animationRef.current.offsetHeight;

    gsap.killTweensOf(`#${id} *`);
    animationStage2Base(0, w, h);
    gsap.to(`#${id} .${classPrefix}__label-foundations-3`, {
      duration,
      opacity: 1,
    });
    gsap.to(
      `#${id} .${classPrefix}__label-foundations-1, #${id} .${classPrefix}__label-foundations-2`,
      { duration, opacity: 0 }
    );
    gsap.to(`#${id} .${classPrefix}__platform-3 .top`, {
      duration,
      fill: '#fdeee8',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-2 .top, #${id} .${classPrefix}__platform-2 .front, #${id} .${classPrefix}__platform-2 .side, #${id} .${classPrefix}__platform-3 .front, #${id} .${classPrefix}__platform-3 .side`,
      { duration, fill: '#f6cac8' }
    );
    gsap.to(`#${id} .${classPrefix}__platform-1 .top`, {
      duration,
      fill: '#e8834d',
    });
    gsap.to(
      `#${id} .${classPrefix}__platform-1 .front, #${id} .${classPrefix}__platform-1 .side`,
      { duration, fill: '#e54f47' }
    );
  }, [id, animationStage2Base]);

  useEffect(() => {
    const intersectionRef = animationRef.current;

    const intersectionCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.1) {
          if (currentAnimationStage === 1) {
            animation1in();
          } else if (currentAnimationStage === 2) {
            animation2in();
          }
        }
      });
    };

    const observer = new IntersectionObserver(intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });
    observer.observe(intersectionRef);

    if (isMobileOnly) {
      if (currentAnimationStage === 2) {
        animation1in();
      } else if (currentAnimationStage === 'highlight-skpr') {
        animationHighlightSkpr();
      } else if (currentAnimationStage === 'highlight-kube') {
        animationHighlightKube();
      } else if (currentAnimationStage === 'highlight-aws') {
        animationHighlightAws();
      }
    }

    return () => {
      observer.unobserve(intersectionRef);
    };
  }, [
    currentAnimationStage,
    isMobileOnly,
    animation1in,
    animation2in,
    animationHighlightSkpr,
    animationHighlightKube,
    animationHighlightAws,
  ]);

  useEffect(() => {
    if (!isMobileOnly) {
      if (currentAnimationStage === '0') {
        if (currentAnimationIsAbove) {
          animation1out();
        }
      } else if (currentAnimationStage === '1') {
        currentAnimationIsAbove ? animation2out() : animation1in();
      } else if (currentAnimationStage === '2') {
        if (!currentAnimationIsAbove) {
          animation2in();
        }
      } else if (currentAnimationStage === 'highlight-skpr') {
        animationHighlightSkpr();
      } else if (currentAnimationStage === 'highlight-kube') {
        animationHighlightKube();
      } else if (currentAnimationStage === 'highlight-aws') {
        animationHighlightAws();
      }
    }
  }, [
    currentAnimationStage,
    isMobileOnly,
    animation1in,
    animation1out,
    animation2in,
    animation2out,
    animationHighlightSkpr,
    animationHighlightKube,
    animationHighlightAws,
    currentAnimationIsAbove,
  ]);

  return (
    <div ref={animationRef} id={id} className={classes}>
      <SvgCircle className={`${classPrefix}__circle`} />
      <SvgPlatform className={`${classPrefix}__platform-1`} />
      <SvgPlatform className={`${classPrefix}__platform-2`} />
      <SvgPlatform className={`${classPrefix}__platform-3`} />
      <SvgLabelProblem1 className={`${classPrefix}__label-problem-1`} />
      <SvgLabelProblem2 className={`${classPrefix}__label-problem-2`} />
      <SvgLabelProblem3 className={`${classPrefix}__label-problem-3`} />
      <SvgLabelSolution1 className={`${classPrefix}__label-solution-1`} />
      <SvgLabelFoundations1 className={`${classPrefix}__label-foundations-1`} />
      <SvgLabelFoundations2 className={`${classPrefix}__label-foundations-2`} />
      <SvgLabelFoundations3 className={`${classPrefix}__label-foundations-3`} />
      <div className={`${classPrefix}__planes`}>
        <SvgPlane1 className={`${classPrefix}__plane-1`} />
        <SvgPlane2 className={`${classPrefix}__plane-2`} />
        <SvgPlane3 className={`${classPrefix}__plane-3`} />
      </div>
      <div className={`${classPrefix}__boxes`}>
        <SvgBox1 className={`${classPrefix}__box-1`} />
        <SvgBox2 className={`${classPrefix}__box-2`} />
        <SvgBox3 className={`${classPrefix}__box-3`} />
        <SvgBox4 className={`${classPrefix}__box-4`} />
        <SvgBox5 className={`${classPrefix}__box-5`} />
        <SvgBox6 className={`${classPrefix}__box-6`} />
        <SvgBox7 className={`${classPrefix}__box-7`} />
        <SvgBox8 className={`${classPrefix}__box-8`} />
      </div>
    </div>
  );
};

HowItWorksAnimation.displayName = 'HowItWorksAnimation';
HowItWorksAnimation.propTypes = {
  currentAnimationStage: PropTypes.string.isRequired,
  currentAnimationIsAbove: PropTypes.bool.isRequired,
};

export default HowItWorksAnimation;
